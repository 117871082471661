<script>
const layoutTypes = {
  mobile: defineAsyncComponent(() => import('./Mobile')),
  tablet: defineAsyncComponent(() => import('./Desktop')),
  desktop: defineAsyncComponent(() => import('./Desktop')),
  small_desktop: defineAsyncComponent(() => import('./Desktop')),
};

export default defineNuxtComponent({
  name: 'SidebarSecondLevel',
  props: {
    secondSidebarCollapsed: {
      type: Boolean,
      default: false,
      required: true,
    },
    selectedNavigationItem: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    deviceType: {
      type: String,
      default: 'desktop',
      required: false,
    },
  },
  computed: {
    layoutTypeComponent() {
      return layoutTypes[this.deviceType];
    },
  },
  methods: {
    onHideClick() {
      this.$emit('onHideClick');
    },
  },
});
</script>

<template>
  <div>
    <component
      :is="layoutTypeComponent"
      :second-sidebar-collapsed="secondSidebarCollapsed"
      :selected-navigation-item="selectedNavigationItem"
      @on-hide-click="onHideClick"
    />
  </div>
</template>
