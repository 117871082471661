<script>
import { mapGetters } from 'vuex';

import SidebarFirstLevel from './components/SidebarFirstLevel';
import SidebarSecondLevel from './components/SidebarSecondLevel';

import SidebarMixin from '@/components/molecules/Sidebar/mixins/SidebarMixin';
import DisplayMixin from '@/mixins/DisplayMixin';

export default {
  name: 'Sidebar',
  components: { SidebarFirstLevel, SidebarSecondLevel },
  mixins: [SidebarMixin, DisplayMixin],
  props: {
    user: {
      type: Object,
      required: true,
      default: () => { },
    },
    navigation: {
      type: Array,
      required: true,
      default: () => [],
    },
    locations: {
      type: Array,
      required: true,
      default: () => [],
    },
    checkedLocation: {
      type: Number,
      required: true,
      default: 0,
    },
    deviceType: {
      type: String,
      default: 'desktop',
      required: false,
    },
  },
  emits: [
    'onNavigationItemClick',
    'onSecondNavigationLevelElementClick',
    'onLocationChanged',
  ],
  computed: {
    ...mapGetters('SidebarStore', ['selectedNavigationItem']),
  },
  beforeMount() {
    this.listen();
  },
  beforeUnmount() {
    this.listen('off');
  },
  methods: {
    listen(status = 'on') {
      this.$eventBus[`$${status}`]('onLocationChanged', this.onLocationChanged);
      this.$eventBus[`$${status}`](
        'onNavigationItemClick',
        this.onNavigationItemClick,
      );
      this.$eventBus[`$${status}`](
        'onSecondNavigationLevelElementClick',
        this.onSecondNavigationLevelElementClick,
      );
      this.$eventBus[`$${status}`]('displayChanged', this.onDisplayChanged);
    },
    onMouseEnter() {
      this.setSidebarIsCollapsed(false);
      if (this.selectedNavigationItem.children?.length)
        this.collapseSecondSidebarLevel(false);
    },
    onMouseLeave() {
      this.setSidebarIsCollapsed(true);
      if (
        !this.selectedNavigationItem
        || !this.selectedNavigationItem.children?.length
      )
        this.collapseSecondSidebarLevel(true);
    },
    onDisplayChanged() {
      this.setSidebarIsCollapsed(true);
      this.setSecondSidebarIsCollapsed(true);
    },
    onLocationChanged(location) {
      this.$emit('onLocationChanged', location);
    },
    collapseSecondSidebarLevel(collapsed) {
      this.setSecondSidebarIsCollapsed(collapsed);
    },
    onNavigationItemClick(item) {
      this.$store.commit('SidebarStore/setSelectedNavigationItem', item);
      this.$emit('onNavigationItemClick', item);
    },
    onSecondNavigationLevelElementClick(item) {
      this.$emit('onSecondNavigationLevelElementClick', item);
    },
  },
};
</script>

<template>
  <div class="flex h-full">
    <SidebarFirstLevel
      :sidebar-collapsed="isSidebarCollapsed" :second-sidebar-collapsed="isSecondSidebarCollapsed"
      :user="user" :locations="locations" :checked-location="checkedLocation" :navigation="navigation"
      :device-type="deviceType" :selected-navigation-item="selectedNavigationItem" @on-mouse-enter-sidebar="onMouseEnter()"
      @on-mouse-leave-sidebar="onMouseLeave()"
    />

    <SidebarSecondLevel
      v-if="selectedNavigationItem" :second-sidebar-collapsed="isSecondSidebarCollapsed"
      :selected-navigation-item="selectedNavigationItem" :device-type="deviceType"
      @on-hide-click="collapseSecondSidebarLevel(true)"
    />
  </div>
</template>
