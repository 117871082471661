<script>
const layoutTypes = {
  mobile: defineAsyncComponent(() => import('./Mobile')),
  tablet: defineAsyncComponent(() => import('./Tablet')),
  desktop: defineAsyncComponent(() => import('./Desktop')),
  small_desktop: defineAsyncComponent(() => import('./Desktop')),
};

export default defineNuxtComponent({
  name: 'SidebarFirstLevel',
  props: {
    user: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    locations: {
      type: Array,
      required: true,
      default: () => [],
    },
    checkedLocation: {
      type: Number,
      required: true,
      default: 0,
    },
    navigation: {
      type: Array,
      required: true,
      default: () => [],
    },
    sidebarCollapsed: {
      type: Boolean,
      default: false,
      required: true,
    },
    secondSidebarCollapsed: {
      type: Boolean,
      default: false,
      required: false,
    },
    deviceType: {
      type: String,
      default: 'desktop',
      required: false,
    },
    selectedNavigationItem: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  computed: {
    layoutTypeComponent() {
      return layoutTypes[this.deviceType];
    },
  },
  methods: {
    onMouseEnter() {
      this.$emit('onMouseEnterSidebar');
    },
    onMouseLeave() {
      this.$emit('onMouseLeaveSidebar');
    },
  },
});
</script>

<template>
  <div class="z-50">
    <component
      :is="layoutTypeComponent"
      :user="user"
      :locations="locations"
      :checked-location="checkedLocation"
      :navigation="navigation"
      :sidebar-collapsed="sidebarCollapsed"
      :second-sidebar-collapsed="secondSidebarCollapsed"
      :selected-navigation-item="selectedNavigationItem"
      @on-mouse-enter-sidebar="onMouseEnter"
      @on-mouse-leave-sidebar="onMouseLeave"
    />
  </div>
</template>
